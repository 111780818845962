import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'danger',
        label: 'Personal',
      },
      {
        color: 'primary',
        label: 'e2i',
      },
      // {
      //   color: 'success',
      //   label: 'Google',
      // },
      // {
      //   color: 'info',
      //   label: 'Microsoft',
      // },
      // {
      //   color: 'warning',
      //   label: 'Family',
      // },
    ],
    selectedCalendars: ['Personal', 'e2i'],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, { calendars }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/calendar/events', {
            params: {
              calendars: calendars.join(','),
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      this.$http
        .get(`/api/events`)
        .then(response => {
          const { message } = response.data
        })
        .catch(error => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Something went wrong',
                icon: 'AlertCircleIcon',
                text: 'Please try again later',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
      // return new Promise((resolve, reject) => {
      //   axios
      //     .post('/apps/calendar/events', { event })
      //     .then(response => resolve(response))
      //     .catch(error => reject(error))
      // })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        let start_time = this.createDateFromTime(event.start_time)
        let end_time = this.createDateFromTime(event.end_time)

        start_time = new Date(start_time.toISOString())
        end_time = new Date(end_time.toISOString())

        const form_data = {
          start_date: event.start_date,
          start_time,
          end_date: event.end_date,
          end_time,
          name: event.title,
          description: event.description,
        }
        axios
          .put(`/api/event/${event.id}`, form_data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
