<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <b-button
        v-if="false"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        variant="primary"
        block
        :to="{ name: 'manage-booking-slots' }"
      >
        Manage Booking Slots
      </b-button>
      <b-button
        v-if="$store.state.auth.ActiveUser.account_type === this.$AccountTypes.AGENCY"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        variant="primary"
        block
        @click="toggleAddEventForm"
      >
        Add Events
      </b-button>

      <!-- <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        variant="primary"
        block
        :to="{ name: 'manage-booking-slots' }"
      >
        Manage Calendar Integration
      </b-button> -->

      <div
        v-if="$store.state.auth.ActiveUser.account_type === this.$AccountTypes.AGENCY"
        :class="{
          'mt-3' : $store.state.auth.ActiveUser.email_address.includes('administrator'),
          'mt-1' : !$store.state.auth.ActiveUser.email_address.includes('administrator'),
        }"
      >
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">Calendars</span>
        </h5>
        <b-form-checkbox
          v-model="checkAll"
          class="mb-1 custom-control-success"
        >
          View All
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedCalendars"
            name="event-filter"
            stacked
          >
            <template
              v-for="item in calendarOptions"
            >
              <b-form-checkbox
                v-if="item.label !== 'e2i'"
                :key="item.label"
                name="event-filter"
                :value="item.label"
                class="mb-1"
                :class="`custom-control-${item.color}`"
              >
                {{ item.label }}
              </b-form-checkbox>
            </template>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>

    <div v-if="false">
      <!-- Google Calendar -->
      <b-button
        v-if="!googleAuthorized && !isGoogleAccessTokenValid"
        variant="success"
        class=" mx-1 my-1 w-1oo"
        @click="handleAuthClick"
      >Sign In With Google</b-button>
      <b-button
        v-else
        variant="outline-success"
        class=" mx-1 my-1 w-1oo"
        @click="handleGoogleSignOut"
      >Sign Out From Google</b-button>

      <a
        v-if="!microsoftAuthorized"
        :href="microsoftAuthUrl"
      >
        <b-button
          icon-pack="feather"
          icon="icon-key"
          variant="info"
          class=" mx-1 my-1 w-1oo"
        >Sign In With Microsoft</b-button>
      </a>
      <a
        v-else
        :href="`#`"
        @click="handleMicrosoftSignOut"
      >
        <b-button
          icon-pack="feather"
          icon="icon-key"
          variant="outline-info"
          class=" mx-1 my-1 w-1oo"
        >Sign Out from Microsoft</b-button>
      </a>

    </div>

    <!-- <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" /> -->
    <b-img :src="require('@/assets/images/pages/calendar-schedule.webp')" />
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
    isGoogleAccessTokenValid: {
      //  TODO: google does not provide refresh token so for the work around will have to prompt user to click for now
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const {
      calendarOptions,
      selectedCalendars,
      checkAll,
    } = useCalendarSidebar()

    return {
      calendarOptions,
      selectedCalendars,
      checkAll,
    }
  },
  data() {
    return {
      tokenClient: null,
      gapiInited: false,
      gisInited: false,
      showGoogleButtons: false,
      googleAuthorized: false,

      // TODO: move these to env file
      // HeadHunters HQ
      CLIENT_ID: '520706625283-4v4114hgs52efgf3djof6rmom7jujnfb.apps.googleusercontent.com',
      API_KEY: 'AIzaSyCP7FJis9jvpOYyBXcwQODeaKIRh4FrEI4',

      // // Sugiantos Credentials "My First Project"
      // CLIENT_ID: '231388935606-b1m8g8mm9adjkfn5bipta3rthq6eb65l.apps.googleusercontent.com',
      // API_KEY: 'AIzaSyCtXA2W5kg_j0TIR16RE57aQb86duMB56Q',

      DISCOVERY_DOC: 'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
      SCOPES: 'https://www.googleapis.com/auth/calendar',

      // microsoft
      microsoftAuthUrl: null,
      microsoftAuthorized: false,
    }
  },
  watch: {
    selectedCalendars(val, oldVal) {
      //
    },
  },
  created() {
    // this.gapiLoaded()
    // this.gisLoaded()

    // this.get_microsoft_auth_url()

    // if (this.$route.query.code) { // if redirected from microsoft login
    //   this.exchangeMicrosoftAuthCode() // exchange auth_code with access_token in backend as well as save access_token to user
    //   this.$router.push({ name: 'events-calendar' }) // to remove ?code from url
    // }
  },
  methods: {
    // Google
    gapiLoaded() {
      window.gapi.load('client', this.gapiInit);
    },
    async gapiInit() {
      await window.gapi.client.init({
        apiKey: this.API_KEY,
        discoveryDocs: [this.DISCOVERY_DOC],
      })

      this.gapiInited = true;
      this.checkShowGoogleButtons();
    },
    gisLoaded() {
      this.tokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: this.CLIENT_ID,
        scope: this.SCOPES,
        access_type: 'offline',
        callback: '', // defined later
      })
      this.gisInited = true;
      this.checkShowGoogleButtons()
    },
    checkShowGoogleButtons() {
      if (this.gapiInited && this.gisInited) {
        this.showGoogleButtons = true
      }

      if (localStorage.getItem('googleAccessToken') !== null) { // check if user is logged in
        this.googleAuthorized = true
      }

      if (localStorage.getItem('microsoftAccessToken') !== null) { // check if user is logged in
        this.microsoftAuthorized = true
      }
    },
    handleAuthClick() {
      this.tokenClient.callback = async resp => {
        if (resp.error !== undefined) {
          throw (resp)
        }
        this.googleAuthorized = true

        const googleToken = window.gapi.client.getToken()

        this.$http // save google token to current users document
          .put(`/api/users-google-login`, { data: googleToken })
          .then(response => {
            localStorage.setItem('googleAccessToken', response.data.token.token)

            this.$emit('get-google-events')
          })
          .catch(() => {})
      };

      this.tokenClient.requestAccessToken({ access_type:'offline', prompt: '' })
    },
    handleGoogleSignOut() {
      // const token = window.gapi.client.getToken()
      const googleAccessToken = localStorage.getItem('googleAccessToken')
      if (googleAccessToken !== null) {
        window.google.accounts.oauth2.revoke(googleAccessToken)
        window.gapi.client.setToken('')

        localStorage.removeItem('googleAccessToken')
        this.googleAuthorized = false
        this.showGoogleButtons = true

        // TODO: instead of refreshing page might be better to filter out the calendar events
        this.$emit('calendar-sign-out', 'google')
        // this.$router.go() // to remove google events
      }
    },

    // Microsoft
    get_microsoft_auth_url() {
      this.$http // save google token to user db
        .get(`/api/microsoft/login`)
        .then(response => {
          this.microsoftAuthUrl = response.data
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },
    exchangeMicrosoftAuthCode() {
      this.$http // exchange microsoft auth_code with access_token and save to current user document
        .post(`/api/microsoft/get-access-token`, { data: { code: this.$route.query.code } })
        .then(response => {
          localStorage.setItem('microsoftAccessToken', response.data.microsoft_token)
          this.microsoftAuthorized = true

          this.$emit('get-microsoft-events')
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },
    handleMicrosoftSignOut() {
      this.$http
        .get(`/api/microsoft/logout`)
        .then(response => {
          if (response.data.success) {
            localStorage.removeItem('microsoftAccessToken')
            this.microsoftAuthorized = false

            // TODO: instead of refreshing page might be better to filter out the calendar events
            this.$emit('calendar-sign-out', 'microsoft')
            // this.$router.go() // to remove microsoft events
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },
    refreshFilter() {
      //
    },
    toggleAddEventForm() {
      this.$emit('toggle-add-event-form', true)
    },
  },
}
</script>

<style>

</style>
